<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<partida-form-header :modelPk="modelPk"></partida-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('partida.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#equipamientos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('partida.equipamientostab') }}</v-tab>
				<v-tab :key="2" :href="'#vehiculos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('partida.vehiculostab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'maintab'">
					<pui-field-set :title="getTitleDatosIdentificativos" highlighted class="pt-3">
						<v-layout row wrap>
							<v-flex xs12 sm6 md2 v-if="!isCreatingElement">
								<!-- IDPARTIDA -->
								<pui-text-field
									:id="`idpartida-partida`"
									v-model="model.idpartida"
									:label="$t('partida.idpartida')"
									:disabled="formDisabled"
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<!-- NUMDECLARACION -->
								<pui-text-field
									:id="`numdeclaracion-partida`"
									v-model="model.numdeclaracion"
									:label="$t('partida.numdeclaracion')"
									:disabled="formDisabled"
									toplabel
									maxlength="11"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<!-- TIPODECLARACION -->
								<pui-text-field
									:id="`tipodeclaracion-partida`"
									v-model="model.tipodeclaracion"
									:label="$t('partida.tipodeclaracion')"
									:disabled="formDisabled"
									toplabel
									maxlength="1"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<!-- NUMPARTIDA -->
								<pui-text-field
									:id="`numpartida-partida`"
									v-model="model.numpartida"
									:label="$t('partida.numpartida')"
									:disabled="formDisabled"
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12 sm6 md2>
								<!-- NUMDOCUMENTO -->
								<pui-text-field
									:id="`numdocumento-partida`"
									v-model="model.numdocumento"
									:label="$t('partida.numdocumento')"
									:disabled="formDisabled"
									toplabel
									maxlength="18"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<!-- TIPODOC -->
								<pui-text-field
									:id="`tipodoc-partida`"
									v-model="model.tipodoc"
									:label="$t('partida.tipodoc')"
									:disabled="formDisabled"
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 sm6 md2>
								<!-- NUMPARTIDAADU -->
								<pui-text-field
									:id="`numpartidaadu-partida`"
									v-model="model.numpartidaadu"
									:label="$t('partida.numpartidaadu')"
									:disabled="formDisabled"
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="getTitleDatos" class="pt-3">
						<v-layout row wrap>
							<v-flex xs12 md10>
								<!-- MERCANCIA -->
								<pui-text-area
									:id="`mercancia-partida`"
									v-model="model.mercancia"
									:label="$t('partida.mercancia')"
									toplabel
									:disabled="formDisabled"
									maxlength="350"
									rows="2"
								></pui-text-area>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs6 md2>
								<!-- CODARAN -->
								<pui-text-field
									:id="`codaran-partida`"
									v-model="model.codaran"
									:label="$t('partida.codaran')"
									:disabled="formDisabled"
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-flex>
							<v-flex xs6 md2>
								<!-- NUMBULTOS -->
								<pui-number-field
									:id="`numbultos-partida`"
									v-model="model.numbultos"
									:label="$t('partida.numbultos')"
									:disabled="formDisabled"
									toplabel
									integer
									min="0"
									max="999999999"
								></pui-number-field>
							</v-flex>
							<v-flex xs6 md2>
								<!-- TIPOBULTO -->
								<pui-text-field
									:id="`tipobulto-partida`"
									v-model="model.tipobulto"
									:label="$t('partida.tipobulto')"
									:disabled="formDisabled"
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
							<v-flex xs6 md2>
								<!-- PESOBRUTO -->
								<pui-number-field
									:id="`pesobruto-partida`"
									v-model="model.pesobruto"
									:label="$t('partida.pesobruto')"
									:disabled="formDisabled"
									toplabel
									integer
									decimals="2"
									min="0"
									max="999999999"
								></pui-number-field>
							</v-flex>
							<v-flex xs6 md2>
								<!-- TDAPESOBRUTO -->
								<pui-number-field
									:id="`tdapesobruto-partida`"
									v-model="model.tdapesobruto"
									:label="$t('partida.tdapesobruto')"
									:disabled="formDisabled"
									toplabel
									integer
									decimals="2"
									min="0"
									max="999999999"
								></pui-number-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12 md6>
								<!-- MUELLE -->
								<pui-text-field
									:id="`muelle-partida`"
									v-model="model.muelle"
									:label="$t('partida.muelle')"
									:disabled="formDisabled"
									toplabel
									maxlength="20"
								></pui-text-field>
							</v-flex>
							<v-flex xs4 md2>
								<!-- SITADUANERA -->
								<pui-text-field
									:id="`sitaduanera-partida`"
									v-model="model.sitaduanera"
									:label="$t('partida.sitaduanera')"
									:disabled="formDisabled"
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
							<v-flex xs4 md2>
								<!-- CODTARIC -->
								<pui-text-field
									:id="`codtaric-partida`"
									v-model="model.codtaric"
									:label="$t('partida.codtaric')"
									:disabled="formDisabled"
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs4 md2>
								<!-- TOTALBULTOS -->
								<pui-number-field
									:id="`totalbultos-partida`"
									v-model="model.totalbultos"
									:label="$t('partida.totalbultos')"
									:disabled="formDisabled"
									toplabel
									integer
									min="0"
									max="99999999"
								></pui-number-field>
							</v-flex>
							<v-flex xs4 md2>
								<!-- TOTALCONT -->
								<pui-number-field
									:id="`totalcont-partida`"
									v-model="model.totalcont"
									:label="$t('partida.totalcont')"
									:disabled="formDisabled"
									toplabel
									integer
									min="0"
									max="99999999"
								></pui-number-field>
							</v-flex>
							<v-flex xs4 md2>
								<!-- PAISORIGEN -->
								<pui-text-field
									:id="`paisorigen-partida`"
									v-model="model.paisorigen"
									:label="$t('partida.paisorigen')"
									:disabled="formDisabled"
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
							<v-flex xs4 md2>
								<!-- ESTADO -->
								<pui-text-field
									:id="`estado-partida`"
									v-model="model.estado"
									:label="$t('partida.estado')"
									:disabled="formDisabled"
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs4 md3>
								<!-- FECALTA -->
								<pui-date-field
									:id="`fecalta-partida`"
									v-model="model.fecalta"
									:label="$t('partida.fecalta')"
									:disabled="formDisabled"
									toplabel
									time
								></pui-date-field>
							</v-flex>
							<v-flex xs4 md1>
								<!-- BAJA -->
								<pui-text-field
									:id="`baja-partida`"
									v-model="model.baja"
									:label="$t('partida.baja')"
									:disabled="formDisabled"
									toplabel
									maxlength="1"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-tab-item>
				<v-tab-item :key="1" :value="'equipamientos'" v-if="!isCreatingElement && !isModalDialog"
					><pui-master-detail
						componentName="equipamientospartida-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idpartida: 'idpartida' }"
						:modalDialog="isModalDialog"
						:formDisabled="true"
					></pui-master-detail
				></v-tab-item>
				<v-tab-item :key="2" :value="'vehiculos'" v-if="!isCreatingElement && !isModalDialog"
					><pui-master-detail
						componentName="vehiculospartida-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idpartida: 'idpartida' }"
						:modalDialog="isModalDialog"
						:formDisabled="true"
					></pui-master-detail
				></v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import partidaActions from './PartidaActions';

export default {
	name: 'partida-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'partida',
			parentModelName: 'documentosaduanero',
			tabmodel: 'maintab',
			actions: partidaActions.formActions
		};
	},
	methods: {
		afterGetData() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel) {
				if (thisModel.tabFromAction) {
					this.tabmodel = thisModel.tabFromAction;
					delete thisModel.tabFromAction;
				}
			}

			// Do something after get data from server
			this.setParentPk();
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.iddocumento = parentModel.pk.iddocumento;
			}
		}
	},
	computed: {
		getTitleDatosIdentificativos() {
			return this.$t('form.partida.fieldset.ids');
		},
		getTitleDatos() {
			return this.$t('form.partida.fieldset.dt');
		}
	},
	created() {}
};
</script>
