const EquipamientosAction = {
	id: 'equipamientospartidaAction',
	selectionType: 'single',
	label: 'action.equipamientospartidas',
	functionality: 'READ_EQUIPAMIENTOSPARTIDA',
	checkAvailability: function(/*vue, registries*/) {
		return true;
	},
	runAction: function(vue, action, model /*, registries*/) {
		//const nbspObjectPk = utilActions.getPkFromModel(model, registries[0]);
		var nbspObjectPk = { idpartida: model[0].idpartida };
		const pkStr = JSON.stringify(nbspObjectPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		model[0].tabFromAction = 'equipamientos';

		const thisModel = this.$store.getters.getModelByName(action.name);
		thisModel.tabFromAction = 'equipamientos';

		const params = {
			router: this.$router,
			url: `${action.name}/read/${pk64}/`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	gridactions: [EquipamientosAction],
	formactions: [EquipamientosAction]
};
